import Commodity from 'domain/commodity';
import CommodityConfiguration from './commodityConfiguration';
import ThrowsValidationError from 'common/http/throwsValidationError';
import CommodityExchange from 'domain/commodityExchange';
import HttpService from 'common/http/httpService';
import { Currency } from 'domain/currency';
import { Unit, UnitConversionRate } from 'domain/unit';
import { BidViewTypesEnum } from 'features/bidEditor/bidViewTypesEnum';
import { RoundingStrategyEnum } from 'domain/roundingStrategy';

class CommodityService extends HttpService {
	async listCommodities(commodityGroupFilter: string, viewFilter: string): Promise<Commodity[]> {
		const response = await this.getClient().get<Commodity[]>("/settings/v1/commodities", {
			params: {
				results: viewFilter,
				commodityGroup: commodityGroupFilter
			}
		});
		return response.data;
	}
	async listMyCommodities(commodityGroupFilter?: string): Promise<Commodity[]> {
		const response = await this.getClient().get<Commodity[]>("/settings/v1/commodities", {
			params: {
				results: "mine",
				commodityGroup: commodityGroupFilter
			}
		});
		return response.data;
	}

	async getCommodityConfiguration(): Promise<CommodityConfiguration> {
		const response = await this.getClient().get<CommodityConfiguration>(
			"/settings/v1/configuration/commodities"
		);
		return response.data;
	}

	@ThrowsValidationError()
	async updateCommodity(commodity: Commodity): Promise<any> {
		const request: UpdateCommodityRequest ={
			displayName: commodity.displayName,
			isCashOnly: commodity.isCashOnly,
			bidPriceIncrement: commodity.bidPriceIncrement,
			publicBidDisplay: commodity.publicBidDisplay,
			roundingOnClose: commodity.roundingOnClose,
			roundingInSession: commodity.roundingInSession,
			groupWithCommodityId: commodity.groupWithCommodityId,
			priceProtection: commodity.priceProtection, 
			localCurrencyId: commodity.localCurrency?.id,
			localUnitId: commodity.localUnit?.id,
			localUnitConversionRate: commodity.localUnit?.conversionRate,
			erpUnitId: commodity.erpUnit?.id,
			erpUnitConversionRate: commodity.erpUnit?.conversionRate
		}
		await this.getClient().put(`/settings/v1/commodities/${commodity.id}`, request);
	}

	async listExchanges(): Promise<CommodityExchange[]> {
		const response = await this.getClient().get<CommodityExchange[]>(
			"settings/v1/exchanges"
		);
		return response.data;
	}

	async listCurrencies(): Promise<Currency[]> {
		const response = await this.getClient().get<Currency[]>(
			"settings/v1/currencies"
		);
		return response.data;
	}

	async listUnits(): Promise<Unit[]> {
		const response = await this.getClient().get<Unit[]>(
			"settings/v1/units"
		);
		return response.data;
	}

	async listUnitsStandardConversionRates(): Promise<UnitConversionRate[]> {
		const response = await this.getClient().get<UnitConversionRate[]>(
			"settings/v1/units-standard-conversion-rates"
		);
		return response.data;
	}

	async listUnitsCustomConversionRates(): Promise<UnitConversionRate[]> {
		const response = await this.getClient().get<UnitConversionRate[]>(
			"settings/v1/units-custom-conversion-rates"
		);
		return response.data;
	}
}

interface UpdateCommodityRequest {
	displayName : string;
	isCashOnly : boolean;
	bidPriceIncrement? : number;
	publicBidDisplay: BidViewTypesEnum;
	roundingOnClose?: RoundingStrategyEnum;
	roundingInSession?: RoundingStrategyEnum;
	groupWithCommodityId?: number;
	priceProtection?: number; 
	localCurrencyId? : number;
	localUnitId? : number;
	localUnitConversionRate?: number;
	erpUnitId? : number;
	erpUnitConversionRate?: number;
}

const commodityService = new CommodityService();
export default commodityService;
