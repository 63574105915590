import { Producer } from "features/settings/producers/producer";
import { Trader } from "features/settings/traders/trader";
import { AuditUser } from "./auditUser";
import Commodity from "./commodity";
import DeliveryPeriod from "./deliveryPeriod";
import PhysicalLocation from "./physicalLocation";
import FuturesContract from "./futuresContract";
import { DeliveryMethod } from "./deliveryMethod";
import { PaymentOption } from "./paymentOption";
import { Currency, CurrencyEnum } from "./currency";
import Offer from "./offer";
import { PositionSetting } from "domain/positionSetting";
import { MerchantThin } from "./merchant";
import { FeeThin } from "./fee";
import { CustomFieldThin } from "./customField";
import { ProducerAccount } from "features/settings/producers/producerAccount";

export default class Contract {
    id: string;
    displayId: string;
    merchant: MerchantThin;
    internalId: number;
    erpId: string;
    erpDisplayId: string;
    isErpInSync: boolean;
    contractType: ContractType;
    contractSubType?: string;
    quantityContracted: number;
    workingQuantity: number;
    quantityPriced: number;
    remainingQuantity: number;
    cashPrice: number;
    finalPrice: number;
    futuresPrice: number;
    basis: number;
    isBasisLocked: boolean;
    sourceContractId?: number;
    sourceContractType?: ContractType;

    deliveryMethod: DeliveryMethod;    
    freightFee?: number;
    paymentOption: PaymentOption;
    deferredPayDate?: Date;
    interestStartDate?: Date;
    interestRate?: number;
    customerAccount: ProducerAccount;
    expirationDate: Date;
    flag: boolean;
    comments: string;
    deliveryTerms?: string;
    customerContractId?: string;
    
    producer: Producer;
    commodity: Commodity;
    currency: CurrencyEnum;
    deliveryPeriod: DeliveryPeriod;
    htaFee?: number;
    rollFee?: number;
    premium?: number;
    positionSetting: PositionSetting;
    deliveryPeriodStartDate: Date;
    deliveryPeriodEndDate: Date;
    futuresContract: FuturesContract;
    location: PhysicalLocation;
    contractLocation: PhysicalLocation;
    region: PhysicalLocation;
    customerWillSell: boolean;
    trader: Trader;
    createdBy: AuditUser;
    lastModifiedBy: AuditUser;
    lastUpdatedDate: Date;
    offer: Offer;
    fees: FeeThin[];
    customFields: CustomFieldThin[];

    isReadOnly: boolean;
    disablePricings: boolean;
    canEditQuantity: boolean;
    isSupervisorEditing: boolean;
    disableErpSync: boolean;
    contractCurrency: Currency;
}

export enum ContractType {
    Cash = 1,
    Basis = 2,
    Hta = 3,
    DelayedPrice = 4,
    CashMinPrice = 5,
    HtaMinPrice = 6,
}

export const AllContractTypes: ContractType[] = [
    ContractType.Cash,
    ContractType.Basis,
    ContractType.Hta,
    ContractType.DelayedPrice
];

export function getContractTypeName(contractType: ContractType): string {
    switch (contractType) {
        case ContractType.Cash:
            return "Cash";
        case ContractType.Hta:
            return "HTA";
        case ContractType.Basis:
            return "Basis";
        case ContractType.DelayedPrice:
            return "Delayed Price";
        case ContractType.CashMinPrice:
            return "Cash Min Price";
        case ContractType.HtaMinPrice:
            return "HTA Min Price";
        default:
            return "???";
    }
}