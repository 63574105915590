import { ContractType } from "./contract";
import { OfferTypeEnum } from "./offerType";

export enum PaymentOption {
    Check = 1,
    ACH,
    DeferredPay,
    NoDateEstablished
}

export const AllPaymentOptions: PaymentOption[] = [
    PaymentOption.Check,
    PaymentOption.ACH,
    PaymentOption.DeferredPay,
    PaymentOption.NoDateEstablished
];

export function listPaymentOptionsForOffer(offerType: OfferTypeEnum): PaymentOption[] {
    switch (offerType) {
        case OfferTypeEnum.Cash:
        case OfferTypeEnum.CashMinPrice:
        case OfferTypeEnum.FixBasisOnHta:
        case OfferTypeEnum.FixFuturesOnBasis:
            return AllPaymentOptions;
        default:
            return AllPaymentOptions.filter(m => m !== PaymentOption.DeferredPay);
    }
}

export function listPaymentOptionsForContract(contractType: ContractType): PaymentOption[] {
    switch (contractType) {
        case ContractType.Cash:
        case ContractType.CashMinPrice:
            return AllPaymentOptions;
        default:
            return AllPaymentOptions.filter(m => m !== PaymentOption.DeferredPay);
    }
}

export function getPaymentOptionName(paymentOption: PaymentOption): string {
    switch (paymentOption) {
        case PaymentOption.Check:
            return "Check";
        case PaymentOption.ACH:
            return "ACH";
        case PaymentOption.DeferredPay:
            return "Deferred Pay";
        case PaymentOption.NoDateEstablished:
            return "No Date Established";
        default:
            return "";
    }
}