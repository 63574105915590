import Commodity from "./commodity";
import { Unit, UnitConversionRate } from "./unit";

class UnitHelper {
    
    public getQuantityTextWithUnits(quantity: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): string {
        const baseQuantityText = this.getQuantityWithUnits(quantity, commodity.baseUnit);
        if(commodity.baseUnit.id === commodity.localUnit.id) return baseQuantityText;
       
        const localQuantity = this.getLocalQuantity(quantity, commodity, unitsConversionRates);
        return localQuantity != null ? `${baseQuantityText} / ${this.getQuantityWithUnits(localQuantity, commodity.localUnit)}` : baseQuantityText;
    }

    public getLocalQuantity(quantity: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): number {
        return this.getConvertedQuantity(quantity, commodity.id, commodity.baseUnit.id, commodity.localUnit.id, unitsConversionRates);
    }

    public getBaseQuantity(quantity: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): number {
        return this.getConvertedQuantity(quantity, commodity.id, commodity.localUnit.id, commodity.baseUnit.id, unitsConversionRates);
    }

    private getConvertedQuantity(quantity: number, commodityId: number, fromUnitId: number, toUnitId: number, unitsConversionRates: UnitConversionRate[]): number {
        if(quantity == null || quantity === 0) return quantity;

        const conversionRate =  unitsConversionRates?.find(c => c.commodityId === commodityId &&
            c.fromUnitId === fromUnitId && c.toUnitId === toUnitId)?.conversionRate;

        if(conversionRate == null) return null;

        return Number((quantity * conversionRate).toFixed(2));
    }

    private getQuantityWithUnits(quantity: number, unit: Unit): string {
        return `${quantity.toLocaleString()} ${unit.abbreviation}`;
    }
}

const unitHelper = new UnitHelper()

export default unitHelper;