import { PositionHedge } from "./positionHedge";
import { formatDate } from "common/formatters";
import { OrderExpirationType } from "domain/orderExpirationType";
import ThrowsValidationError from "common/http/throwsValidationError";
import HttpService from "common/http/httpService";

class PositionHedgesService extends HttpService {
    
    @ThrowsValidationError()
    async addPositionHedge(positionHedge: PositionHedge, positionBalanceId: number): Promise<PositionHedge> {
        const createRequest = {
            positionBalanceId: positionBalanceId,
            customerWillSell: positionHedge.customerWillSell,
            commodityId: positionHedge.contract?.commodity?.id,
            futuresContractId: positionHedge.contract.id,
            fcmAccountId: positionHedge.fcmAccount.id,
            locationId: positionHedge.location?.id,
            numberOfContracts: positionHedge.numberOfContracts,
            price: positionHedge.price,
            orderType: positionHedge.orderType,
            maxShow: positionHedge.maxShow,
            strategyType: positionHedge.strategyType,
            positionHedgeType: positionHedge.positionHedgeType,
            isMarketPrice: positionHedge.isMarketPrice,
            orderExpirationType: positionHedge.orderExpirationType,
            orderExpirationDate: positionHedge.orderExpirationType === OrderExpirationType.GoodTilDate ? formatDate(positionHedge.orderExpirationDate, "yyyy-MM-DD") : null,
            comments: positionHedge.comments,
            producerId: positionHedge.producer?.id,
            partialPositionHedgeId: positionHedge.partialPositionHedgeId,
            tasFallbackOption: positionHedge.tasFallbackOption
        };
        const response = await this.getClient().post<PositionHedge>("/offers/v1/position-hedges", createRequest);
        return response.data;
    }

    @ThrowsValidationError()
    async updatePositionHedge(positionHedge: PositionHedge): Promise<any> {
        const updateRequest = {
            commodityId: positionHedge.contract?.commodity?.id,
            futuresContractId: positionHedge.contract.id,
            fcmAccountId: positionHedge.fcmAccount.id,
            locationId: positionHedge.location?.id,
            numberOfContracts: positionHedge.numberOfContracts,
            price: positionHedge.price,
            orderType: positionHedge.orderType,
            maxShow: positionHedge.maxShow,
            positionHedgeType: positionHedge.positionHedgeType,
            isMarketPrice: positionHedge.isMarketPrice,
            orderExpirationType: positionHedge.orderExpirationType,
            orderExpirationDate: positionHedge.orderExpirationType === OrderExpirationType.GoodTilDate ? formatDate(positionHedge.orderExpirationDate, "yyyy-MM-DD") : null,
            comments: positionHedge.comments,
            producerId: positionHedge.producer?.id,
            tasFallbackOption: positionHedge.tasFallbackOption
        };
        const response = await this.getClient().put(`/offers/v1/position-hedges/${positionHedge.id}`, updateRequest);
        return response.data;
    }

    async cancelPositionHedge(positionHedge: PositionHedge): Promise<PositionHedge> {
        const response = await this.getClient().post<PositionHedge>(`/offers/v1/position-hedges/${positionHedge.id}/cancel`);
        return response.data;
    }

    async getPositionHedge(id: number): Promise<PositionHedge> {
        const response = await this.getClient().get<PositionHedge>(`/offers/v1/position-hedges/${id}`);
        return response.data;
    }

}

const positionHedgesService = new PositionHedgesService();
export default positionHedgesService;
