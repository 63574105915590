import { sumArray } from "common/decimal";
import Contract, { ContractType } from "domain/contract";
import contractTypeHelper from "domain/contractTypeHelper";
import { DeliveryMethod } from "domain/deliveryMethod";
import { OfferTypeEnum } from "domain/offerType";
import { UserConfiguration } from "features/login/loggedInUser";
import { Offering } from "features/settings/offerings/offering";
import { Trader } from "features/settings/traders/trader";
import * as NP from "number-precision";

export function allowEdit(contract: Contract, userConfiguration: UserConfiguration, trader: Trader): boolean {
    if((contract.customerWillSell && !userConfiguration.canSell) || 
        (!contract.customerWillSell && !userConfiguration.canBuy)) {
        return false;
    }

    if (!isContractTypeAllowed(trader == null, contract.contractType, userConfiguration)) {
        return false;
    }

    if (contract.location != null && trader != null && !trader.locations.some(l => l.id === contract.location.id)) return false;

    if(contract.isReadOnly) return false;

    return true;
}

export function allowAddOfferOrContract(contract: Contract, offerings: Offering[], isCustomer: boolean, userConfiguration: UserConfiguration): boolean {
    if(!userConfiguration.canMakeOffer)
    {
        return false;
    }

    if (!isContractTypeAllowed(isCustomer, contract.contractType, userConfiguration)) {
        return false;
    }

    if (contract.contractType === ContractType.DelayedPrice && (!isCustomer || 
            (offerings.some(o => o.location.id === contract.location?.id && o.commodity.id === contract.commodity.id && o.contractTypes.includes(ContractType.DelayedPrice)))
            )) {
           return true;
        }
    return false;
}

export function allowSetBasis(contract: Contract, offerings: Offering[], isCustomer: boolean, userConfiguration: UserConfiguration): boolean {
    if(!userConfiguration.canMakeOffer) return false;

    if(contract.disablePricings) return false;

    if (!isContractTypeAllowed(isCustomer, ContractType.Cash, userConfiguration)) {
        return false;
    }

    if (contractTypeHelper.isHta(contract.contractType) && contract.remainingQuantity !== 0 && (!isCustomer || 
        (offerings.some(o => o.location.id === contract.location?.id && o.commodity.id === contract.commodity.id && o.offerTypes.includes(OfferTypeEnum.FixBasisOnHta)))
        )) {
        return true;
    }
    return false;
}

export function allowSetFutures(contract: Contract, offerings: Offering[], isCustomer: boolean, userConfiguration: UserConfiguration): boolean {
    if(!userConfiguration.canMakeOffer) return false;
    if(contract.disablePricings) return false;

    if (!isContractTypeAllowed(isCustomer, ContractType.Cash, userConfiguration)) {
        return false;
    }
    
    if (contract.contractType === ContractType.Basis && 
        (!isCustomer || (offerings.some(o => o.location.id === contract.location?.id && o.commodity.id === contract.commodity.id && 
            o.offerTypes.includes(OfferTypeEnum.FixFuturesOnBasis))))) {
        return true;
    }
    return false;
}

export function isCashContractWithOpenStorage(contract: Contract) {
    return contractTypeHelper.isCash(contract.contractType) && contract.deliveryMethod === DeliveryMethod.OpenStorage;
}

export function getTotalFees(contract: Contract) {
    const extendedFeesSum = sumArray(contract.fees?.map(m => m.value ?? 0));
    const totalFees = NP.plus(contract.htaFee ?? 0, extendedFeesSum, contract.rollFee ?? 0, contract.freightFee ?? 0);
    return totalFees;
}

export function isContractTypeAllowed(isCustomer: boolean, contractType: ContractType, userConfiguration: UserConfiguration) {
    return isCustomer || userConfiguration.allowedContractTypes.includes(contractType)
}