import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import { OfferStatus } from "domain/offerStatus";
import IDispatcher from "common/dispatcher";
import { OfferAdded, OfferStatusUpdated } from "./offers.actions";
import offerService from './offerService';
import _ from 'lodash';

export interface OfferUpdatedEvent {
    offerId: number;
    oldStatus: OfferStatus;
    newStatus: OfferStatus;
    message?: string;
}

export interface OfferCreatedEvent {
    offerId: number;
    status: OfferStatus;
}

export interface IOfferEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
}

const audio = new Audio('/media/bell.mp3');
const playAlert = _.throttle(() => {
    audio.play();
}, 5000, { leading: true, trailing: false });

export class OfferEventStreamingService implements IOfferEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;
        
        this.dispatcher = dispatcher;
        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on(OfferEvents.OfferCreated, ev => this.onOfferCreated(ev));
        conn.on(OfferEvents.OfferUpdated, ev => this.onOfferUpdated(ev));
        conn.on(OfferEvents.OrderCancelFailed, ev => this.onOrderCancelFailed(ev));
        conn.on(OfferEvents.OrderRejected, ev => this.onOrderRejected(ev));
    }

    private async onOfferCreated(offerEvent: OfferCreatedEvent) {
        const offer = await offerService.getOffer(offerEvent.offerId); 
        this.dispatcher.dispatch(new OfferAdded(offer).toObject());
        this.playAlertIfOfferIsPending(offer.status);
    }

    private async onOfferUpdated(offerEvent: OfferUpdatedEvent) {
        const offer = await offerService.getOffer(offerEvent.offerId); 
        this.dispatcher.dispatch(new OfferStatusUpdated(offer, offerEvent.oldStatus).toObject());
        if(offerEvent.oldStatus !== offerEvent.newStatus) {
            this.playAlertIfOfferIsPending(offerEvent.newStatus);
        }
    }

    private async onOrderCancelFailed(offerEvent: OfferUpdatedEvent) {
        const offer = await offerService.getOffer(offerEvent.offerId); 
        this.dispatcher.dispatch(new OfferStatusUpdated(offer, offer.status).toObject());
    }

    private async onOrderRejected(offerEvent: OfferUpdatedEvent) {
        const offer = await offerService.getOffer(offerEvent.offerId);
        this.dispatcher.dispatch(new OfferStatusUpdated(offer, offer.status).toObject());
    }

    private playAlertIfOfferIsPending(offerStatus: OfferStatus) {
        if(offerStatus === OfferStatus.Pending) {
            playAlert();
        }
    }
}

export const OfferEvents = {
    OfferCreated: "OfferCreated",
    OfferUpdated: "OfferUpdated",
    OrderCancelFailed: "OrderCancelFailed",
    OrderRejected: "OrderRejected"
}

const offerEventStreamingService: IOfferEventStreamingService = new OfferEventStreamingService();
export default offerEventStreamingService;