import { ContractType } from "./contract";

class ContractTypeHelper {
    
    public isCash(offerType: ContractType): boolean {
        return offerType == ContractType.Cash || offerType == ContractType.CashMinPrice;
    }

    public isHta(offerType: ContractType): boolean {
        return offerType == ContractType.Hta || offerType == ContractType.HtaMinPrice;
    }

    public hasBasis(offerType: ContractType): boolean {
        return [ContractType.Cash, ContractType.CashMinPrice, ContractType.Basis].includes(offerType);
    }
}

const contractTypeHelper = new ContractTypeHelper()

export default contractTypeHelper;